.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.centered-content {
  height: 100vh; /* 100% of the viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  text-align: center; /* Optional: Center the text within the div */
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropdown .dropdown-menu{
  display: none;
}

.dropdown:hover > .dropdown-menu, .dropend:hover > .dropdown-menu{
  display:block;
  margin-top: .125em;
  margin-left: .125em;
}

@media screen and (min-width: 769px) {
  .dropend:hover > .dropdown-menu{
    position:absolute;
    top:0;
    left: 100%;
  }
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid #000; /* Adjust color and size as needed */
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #000; /* Adjust color and size as needed */
}

.center-vertically {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered {
  text-align: center;
  padding: auto;
}

label {
  margin-right: 20px; /* Adjust the margin as needed for spacing */
  margin-top: 20px;
}

.hidden-column {
  display: none;
}

.settings-button {
  position: relative;
  cursor: pointer;
  font-size: 1em;
  margin-left: 5px;
  padding: 8px; 
  border-radius: 8px; 
  color: #1e88e5; 
  background-color: #ecf0f1; 
  transition: background-color 0.3s, color 0.3s;
}

.settings-button:hover {
  background-color: #1e88e5; 
  color: #ecf0f1;
}

.displyed-column-button {
  position: relative;
  cursor: pointer;
  font-size: 1em;
  margin-left: 5px;
  padding: 8px; 
  border-radius: 8px; 
  color: #1e88e5; 
  background-color: #ecf0f1; 
  transition: background-color 0.3s, color 0.3s;
}

.displyed-column-button:hover {
  background-color: #1e88e5; 
  color: #ecf0f1;
}

.settings-button-sectorial-analysis {
  position: relative;
  cursor: pointer;
  font-size: 1em;
  margin-left: 17px;
  padding: 8px; 
  border-radius: 8px; 
  color: #1e88e5; 
  background-color: #ecf0f1; 
  transition: background-color 0.3s, color 0.3s;
}

.settings-button-sectorial-analysis:hover {
  background-color: #1e88e5; 
  color: #ecf0f1;
}

.show-sector-graphical-view-button {
  position: relative;
  cursor: pointer;
  font-size: 1em;
  margin-left: 5px;
  padding: 8px; 
  border-radius: 8px; 
  color: #1e88e5; 
  background-color: #ecf0f1; 
  transition: background-color 0.3s, color 0.3s;
}

.show-sector-graphical-view-button:hover {
  background-color: #1e88e5; 
  color: #ecf0f1;
}

.copy-symbol-button {
  position: relative;
  cursor: pointer;
  font-size: 1em;
  margin-left: 5px;
  padding: 8px; 
  border-radius: 8px; 
  color: #1e88e5; 
  background-color: #ecf0f1; 
  transition: background-color 0.3s, color 0.3s;
}

.copy-symbol-button:hover {
  background-color: #1e88e5; 
  color: #ecf0f1;
}

.summary-button {
  position: relative;
  cursor: pointer;
  font-size: 1em;
  margin-left: 17px;
  padding: 8px; 
  border-radius: 8px; 
  color: #1e88e5; 
  background-color: #ecf0f1; 
  transition: background-color 0.3s, color 0.3s;
}

.summary-button:hover {
  background-color: #1e88e5; 
  color: #ecf0f1;
}

.curvy-input {
  border: 1px solid #ccc; /* Optional: add a border for input fields */
  border-radius: 8px; /* Adjust the radius for the desired level of curvature */
  padding: 8px; /* Adjust as needed for spacing and padding inside the input field */
}

.table-container {
  padding-right: 17px;
  padding-left: 17px;
  margin-top: 3px;
}

/* SearchBar.css */
.search-container {
  position: relative;
}

.search-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 200px;
  outline: none;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #555;
  cursor: pointer;
}

.generic-button {
  position: relative;
  cursor: pointer;
  font-size: 1em;
  margin-left: 5px;
  padding: 8px; 
  border-radius: 8px; 
  color: #1e88e5; 
  background-color: #ecf0f1; 
  transition: background-color 0.3s, color 0.3s;
}

.generic-button:hover {
  background-color: #1e88e5; 
  color: #ecf0f1;
}

